import styled from 'styled-components';

import introBg from '../img/sell-your-home/Sell_HeaderBG.jpg';

const StyledIntro = styled.section`
	background-image: url(${introBg});
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	@media (max-width: 999px) {
		padding-bottom: 5.5rem;
	}

	.grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 60px;

		@media (max-width: 999px) {
			grid-template-columns: 1fr;
			grid-template-rows: 1fr;
			row-gap: 0;
		}
	}

	.intro-content-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		height: 100%;

		@media (max-width: 999px) {
			display: block;
			height: auto;
		}
	}

	.heading {
		font-size: 5rem;
		line-height: 1.4;
		letter-spacing: 0.05em;
		${(p) => p.color && `color: ${p.color};`}
		text-transform: none;

		@media (max-width: 599px) {
			font-size: 3rem;
		}
	}

	.intro-text {
		margin-top: 3rem;
		max-width: 26em;
	}

	.accent-text {
		font-family: ${(p) => p.theme.fonts.secondary};
		font-size: 2.2rem;
		font-weight: ${(p) => p.theme.fonts.weights.semiBold};
		letter-spacing: 0.05em;
		${(p) => p.color && `color: ${p.color};`}
		margin-top: 3.5rem;
	}

	.button {
		margin-top: 1.2rem;
		white-space: normal;
		line-height: 1.45;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
		height: auto;

		${(p) =>
			p.color &&
			`color: #ffffff; 
      border-color: ${p.color}; 
      background-color: ${p.color};
      &:hover {
        color: ${p.color};
        background-color: rgba(255,255,255, 0);
    }`};
	}

	.intro-image-wrapper {
		box-shadow: 0px 3px 9px #00000065;

		&.mobile {
			max-width: 500px;
			margin-top: 3rem;
		}

		@media (min-width: 1000px) {
			&.mobile {
				display: none;
			}
		}

		@media (max-width: 999px) {
			&.desktop {
				display: none;
			}
		}
	}
`;

export default StyledIntro;
