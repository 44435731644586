import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';

import leftArrow from '../../img/carousel_leftarrow.svg';
import rightArrow from '../../img/carousel_rightarrow.svg';

const StyledCarousel = styled.div`
	blockquote {
		p {
			font-size: 1.8rem;
			font-weight: ${(props) => props.theme.fonts.weights.regular};
			letter-spacing: 0.36px;
			margin-bottom: 3rem;
			position: relative;
			padding-top: 10rem;

			&::before {
				content: '\u201C';
				font-family: ${(props) => props.theme.fonts.secondary};
				font-size: 8rem;
				color: ${(props) => props.theme.colors.primary};
				display: inline-block;
				height: 4rem;
				width: 4rem;
				position: absolute;
				left: 50%;
				top: 2rem;
				transform: translateX(-50%);
			}
		}

		footer {
			font: ${(props) => props.theme.fonts.weights.bold} 17px/35px
				${(props) => props.theme.fonts.primary};
			letter-spacing: 0.31px;
			color: ${(props) => props.theme.colors.textGrey};
			text-transform: uppercase;
		}
	}

	.slider-control-bottomcenter {
		position: relative;
		width: 40rem;
		margin: 0 auto;
		max-width: 100%;
		bottom: -3rem !important;

		@media (max-width: 1087px) {
			padding: 0 1.9rem;
		}

		.nav-button {
			width: 2.1rem;
			height: 1.8rem;
			background-color: transparent;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			border: none;
			cursor: pointer;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			&.prev-button {
				background-image: url(${leftArrow});
				left: 0;
			}

			&.next-button {
				background-image: url(${rightArrow});
				right: 0;
			}
		}

		.indicator-bar {
			width: 30rem;
			max-width: 90%;
			height: 3px;
			background-color: ${(props) => props.theme.colors.textDarkerGrey};
			margin: 0 auto;

			@media (max-width: 599px) {
				/* display: none; */
			}

			.bar {
				width: calc(100% / ${(props) => props.testimonialsLength});
				height: 3px;
				background-color: ${(props) => props.theme.colors.primary};
				transition: 0.4s;
				top: 0px;
				position: relative;
			}
		}
	}
`;

const TestimonialsCarousel = () => {
	const [slide, setSlide] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		}, 1000);
	});

	const data = useStaticQuery(graphql`
		query TestimonialsQuery {
			markdownRemark(frontmatter: { content_type: { eq: "testimonial" } }) {
				frontmatter {
					testimonials {
						name
						quote
					}
				}
			}
		}
	`);

	const { testimonials } = data.markdownRemark.frontmatter;

	return (
		<StyledCarousel testimonialsLength={testimonials.length} current={slide}>
			<Carousel
				heightMode={'max'}
				wrapAround={true}
				autoplay={true}
				autoplayInterval={10000}
				// slideIndex={slide}
				// afterSlide={(prevSlide) => setSlide(prevSlide)}
				renderCenterLeftControls={() => null}
				renderCenterRightControls={() => null}
				renderBottomCenterControls={({
					currentSlide,
					previousSlide,
					nextSlide,
				}) => (
					<>
						<button
							className="nav-button prev-button"
							onClick={() => previousSlide()}
						>
							<span className="visually-hidden">Previous Slide</span>
						</button>
						<div className="indicator-bar">
							<div
								className="bar"
								style={{
									left: `calc((100% / ${
										testimonials.length
									}) * ${currentSlide})`,
								}}
							/>
						</div>
						<button
							className="nav-button next-button"
							onClick={() => nextSlide()}
						>
							<span className="visually-hidden">Next Slide</span>
						</button>
					</>
				)}
			>
				{testimonials.map((testimonial, i) => (
					<blockquote
						className="has-text-centered"
						key={`${testimonial.name}-${i}`}
					>
						<p>{testimonial.quote}</p>
						<footer>{testimonial.name}</footer>
					</blockquote>
				))}
			</Carousel>
		</StyledCarousel>
	);
};

export default TestimonialsCarousel;
