import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import AccentLine from '../components/AccentLine';
import { IconCards } from '../components/icon-cards';
import FeaturedListingsCarousel from '../components/carousels/FeaturedListingsCarousel';

import StyledBuyaHomePage from '../styles/pages/StyledBuyaHomePage';
import StyledIntro from '../styles/StyledIntro';
import theme from '../styles/theme/theme';
import TestimonialsCarousel from '../components/carousels/TestimonialsCarousel';

export const BuyAHomePageTemplate = ({
	intro,
	differenceSection,
	twoColumnSection,
	testimonialsBg,
}) => (
	<StyledBuyaHomePage>
		<StyledIntro
			className="section intro-section"
			color={theme.colors.tertiary}
		>
			<div className="container">
				<div className="grid two-columns">
					<div className="grid-column">
						<div className="intro-content-container">
							<h1 className="heading">Hire a Local Buyer's Agent and Professional Realtor®</h1>
							<AccentLine color={theme.colors.primary} />
							<div className="intro-image-wrapper mobile">
								<Img fluid={intro.image.childImageSharp.fluid} />
							</div>
							<p className="intro-text">{intro.text}</p>
							<p className="accent-text">{intro.accent_text}</p>
							<Link className="button" to="/contact">
								Find Your Next Home
							</Link>
						</div>
					</div>
					<div className="grid-column">
						<div className="intro-image-wrapper desktop">
							<Img fluid={intro.image.childImageSharp.fluid} />
						</div>
					</div>
				</div>
			</div>
		</StyledIntro>
		<section className="section two-column-section">
			<div className="container">
				<div className="grid">
					<div className="grid-column">
						<Img fluid={twoColumnSection.image.childImageSharp.fluid} />
					</div>
					<div className="grid-column">
						<div className="content-container">
							<h2 className="heading heading-style-2">Your Local Experts</h2>
							<p>
								With Parker Coulter Real Estate, you enjoy personal service 24/7
								from an experienced agent who knows the local market inside and
								out, and who’ll provide expert negotiation on your behalf. We’ll
								even connect you with any industry professionals you may need,
								from home inspectors to mortgage brokers and lawyers.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section
			className="section difference-section"
			style={{
				backgroundImage: `url(${differenceSection.section_bg.publicURL})`,
			}}
		>
			<div className="container">
				<div className="columns">
					<div className="column">
						<h2>{differenceSection.heading}</h2>
					</div>
				</div>
				<div className="columns">
					<div className="column">
						<IconCards columns={3} cards={differenceSection.icon_cards} />
						<div className="difference-cta-wrapper">
							<p className="accent-text">Ready to find your new home?</p>
							<Link className="button" to="/contact">
								Get Notified when your dream home gets listed
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
		{/* 
		<section className="section featured-listings-section">
			<div className="container">
				<div className="columns">
					<div className="column has-text-centered">
						<h2>Featured Listings</h2>
						<AccentLine align="center" />
						<p className="sub-head">Find your new home</p>
					</div>
				</div>
				<FeaturedListingsCarousel />
				<div className="has-text-centered">
					<Link className="listings-link" to="/listings">
						See All Listings
					</Link>
				</div>
			</div>
		</section>
		*/}
		<section
			className="section testimonials-section"
			style={{
				backgroundImage: `url(${testimonialsBg.childImageSharp.fluid.src})`,
			}}
		>
			<div className="container">
				<div className="columns">
					<div className="column has-text-centered">
						<h2>Testimonials</h2>
						<AccentLine align="center" />
						<p className="sub-head">What our clients have to say</p>
					</div>
				</div>
				<TestimonialsCarousel />
			</div>
		</section>
	</StyledBuyaHomePage>
);

BuyAHomePageTemplate.propTypes = {
	title: PropTypes.string,
};

const BuyAHomePage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout page={`buy`}>
			<SEO
				title={frontmatter.title}
				description="Whether You’re A First-Time Homebuyer or Looking To Purchase Another Investment Property, Parker Coulter Realty Can Help With Unparralleled Service and Unmatched Expertise."
			/>
			<BuyAHomePageTemplate
				title={frontmatter.title}
				intro={frontmatter.intro}
				differenceSection={frontmatter.difference_section}
				twoColumnSection={frontmatter.two_column_section}
				testimonialsBg={frontmatter.testimonials_bg}
			/>
		</Layout>
	);
};

BuyAHomePage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default BuyAHomePage;

export const buyAHomePageQuery = graphql`
	query BuyAHomePage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			frontmatter {
				title
				intro {
					heading
					text
					accent_text
					image {
						childImageSharp {
							fluid(maxWidth: 1920) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				difference_section {
					heading
					icon_cards {
						icon {
							publicURL
						}
						text
					}
					section_bg {
						publicURL
					}
				}
				two_column_section {
					image {
						childImageSharp {
							fluid(maxWidth: 1920) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				testimonials_bg {
					childImageSharp {
						fluid(maxWidth: 1920) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
	}
`;
