import React from 'react';
import styled from 'styled-components';

const StyledLi = styled.li`
	grid-column: span 2;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	box-shadow: 2px 3px 6px #00000029;
	background-color: ${(p) => p.theme.colors.lightest};
	padding: 4.8rem 2.3rem;
	text-align: center;

	h2 {
		font-size: 32px;
		margin-top: -25px;
	}

	p.detail {
		color: #641823;
		font-size: 14px;
	}

	@media (min-width: 500px) and (max-width: 999px) {
		// Single Orphan
		&:last-child:nth-child(2n - 1) {
			grid-column-end: 4;
		}
	}

	${(p) =>
		p.columns === 3 &&
		`
      @media (min-width: 1000px) {
        /* Dealing with 2 orphan items */
        &:last-child:nth-child(3n - 1) {
          grid-column-end: -2;
        }

        &:nth-last-child(2):nth-child(3n + 1) {
          grid-column-end: 4;
        }

        /* Dealing with single orphan */

        &:last-child:nth-child(3n - 2) {
          grid-column-end: 5;
        }
      }
    `}

	${(p) =>
		p.columns === 4 &&
		`
    @media (min-width: 1000px) {
		  /* Three on last row */
      &:nth-last-child(3):nth-child(4n + 1) {
        grid-column-end: 4;
      }

      &:nth-last-child(2):nth-child(4n + 2) {
        grid-column-end: 6;
      }

      &:last-child:nth-child(4n - 1) {
        grid-column-end: -2;
      }

      /* Two on last row */
      &:nth-last-child(2):nth-child(4n + 1) {
        grid-column-end: 5;
      }

      &:last-child:nth-child(4n - 2) {
        grid-column-end: -3;
      }

      /* Single Orphan */
      &:last-child:nth-child(4n - 3) {
        grid-column-end: 6;
      }
    }
  `}
	

	.icon-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		max-width: 11.3rem;
		margin-bottom: 5rem;
		min-height: 12rem;
		
		img {
			max-height: 120px;
		}
	}

	p.reg {
		min-height: 4em;
	}
`;

const IconCard = ({ icon, text, columns, big, detail }) => (
	<StyledLi columns={columns}>
		<div className="icon-wrapper">
			<img src={icon.publicURL} alt="" />
		</div>
		<p className="reg">{text}</p>
		{big && <h2>{big}</h2>}
		{detail && <p>{detail}</p>}
	</StyledLi>
);

export default IconCard;
