export const collectionOrder = (collection, orderingList) => {
	const ordered = [];
	const notOrdered = [];

	orderingList.forEach(({ mlsNumber }) => {
		let found = false;
		collection = collection.filter(({ node }) => {
			if (!found && node.frontmatter.mls_number === mlsNumber) {
				ordered.push(node);
				found = true;
				return false;
			} else {
				return true;
			}
		});
	});

	orderingList.forEach(({ mlsNumber }) => {
		let found = false;
		collection = collection.filter(({ node }) => {
			if (!found && node.frontmatter.mls_number !== mlsNumber) {
				notOrdered.push(node);
				found = true;
				return false;
			} else {
				found = false;
				return true;
			}
		});
	});

	return ordered.concat(notOrdered);
};
