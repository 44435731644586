import React from 'react';
import styled from 'styled-components';

import IconCard from './IconCard';

const StyledUl = styled.ul`
	display: grid;
	grid-template-columns: repeat(${(p) => p.columns * 2}, 136px);
	column-gap: 1.3rem;
	row-gap: 2.3rem;
	justify-content: center;

	@media (min-width: 500px) and (max-width: 999px) {
		grid-template-columns: repeat(4, 1fr);
	}

	@media (max-width: 499px) {
		grid-template-columns: repeat(2, 1fr);
	}
`;

export const IconCards = ({ cards, columns }) => (
	<StyledUl className="icon-cards" columns={columns}>
		{cards.map((card, i) => (
			<IconCard
				icon={card.icon}
				text={card.text}
				columns={columns}
				key={`${card.text}-${i}`}
				big={card.big}
				detail={card.detail}
			/>
		))}
	</StyledUl>
);
