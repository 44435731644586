import styled from 'styled-components';

const StyledBuyaHomePage = styled.article`
	.intro-section {
		.accent-text {
			color: ${(p) => p.theme.colors.secondary};
		}
	}

	.accent-text {
		font-family: ${(p) => p.theme.fonts.secondary};
		font-size: 2.2rem;
		font-weight: ${(p) => p.theme.fonts.weights.semiBold};
		letter-spacing: 0.05em;
		color: ${(p) => p.theme.colors.primary};
		margin-top: 3.5rem;
	}

	.two-column-section {
		padding-top: 9rem;
		padding-bottom: 9rem;

		.grid {
			display: grid;
			grid-template-columns: 1.2fr 1fr;
			column-gap: 50px;

			.content-container {
				max-width: 44rem;
				margin-top: 3rem;

				.heading {
					margin-bottom: 0.5em;
					color: ${(p) => p.theme.colors.tertiary};
				}
			}
		}

		@media (max-width: 999px) {
			padding-bottom: 3.5rem;

			.grid {
				grid-template-columns: 1fr;
				grid-template-rows: auto 1fr;
				row-gap: 32px;
			}
		}
	}

	.difference-section {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		padding-top: 8rem;
		padding-bottom: 5.5rem;

		h2 {
			color: ${(p) => p.theme.colors.tertiary};
			text-align: center;
			margin-bottom: 3em;
		}

		.difference-cta-wrapper {
			margin-top: 4.2rem;
			text-align: center;

			.accent-text {
				color: ${(p) => p.theme.colors.secondary};
				margin-bottom: 1em;
			}

			.button {
				white-space: normal;
				line-height: 1.45;
				padding-top: 0.5em;
				padding-bottom: 0.5em;
				height: auto;
				color: ${(p) => p.theme.colors.lightest};
				background-color: ${(p) => p.theme.colors.tertiary};
				border-color: ${(p) => p.theme.colors.tertiary};

				&:hover {
					color: ${(p) => p.theme.colors.tertiary};
					background-color: rgba(255, 255, 255, 0);
				}
			}
		}

		@media (max-width: 499px) {
			h2 {
				margin-bottom: 1.5em;
			}
		}
	}

	.featured-listings-section {
		padding-top: 10rem;
		padding-bottom: 5rem;

		@media (max-width: 768px) {
			padding-top: 5rem;
			padding-bottom: 7rem;
		}

		h2 {
			+ [class^='AccentLine'] {
				margin-bottom: 1.5rem;

				+ .sub-head {
					font-family: ${(props) => props.theme.fonts.secondary};
					font-weight: ${(props) => props.theme.fonts.weights.regular};
					font-size: 2.5rem;
					letter-spacing: 3px;
					margin-bottom: 5rem;

					@media (max-width: 768px) {
						margin-bottom: 3rem;
					}
				}
			}
		}

		[class*='FeaturedListingsCarousel'] {
			margin-bottom: 8rem;
		}

		.listings-link {
			font-size: 1.6rem;
			color: #272727;
			background: none;
			border: none;
			text-transform: uppercase;
			position: relative;
			cursor: pointer;

			&::before,
			&::after {
				transition: 500ms cubic-bezier(0.12, -1.05, 0.72, 0.9);
			}

			&::before {
				content: '';
				height: 0.1rem;
				width: 1.3rem;
				right: -2rem;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				background-color: ${(props) => props.theme.colors.primary};
			}

			&::after {
				content: '';
				height: 0.75rem;
				width: 0.75rem;
				right: -1.9rem;
				position: absolute;
				top: 50%;
				transform: translateY(-50%) rotate(-45deg);
				border-right: 1px solid;
				border-bottom: 1px solid;
				border-right-color: ${(props) => props.theme.colors.primary};
				border-bottom-color: ${(props) => props.theme.colors.primary};
			}

			&:hover {
				&::before {
					transform: translateY(-50%) translateX(1rem);
				}

				&::after {
					transform: translateY(-50%) translateX(1rem) rotate(-45deg);
				}
			}
		}
	}

	.testimonials-section {
		padding-top: 10rem;
		padding-bottom: 15rem;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		@media (max-width: 768px) {
			padding-top: 5rem;
			padding-bottom: 10rem;
		}

		h2 {
			+ [class^='AccentLine'] {
				margin-bottom: 1.5rem;
				background-color: ${(props) => props.theme.colors.textGrey};

				+ .sub-head {
					font-family: ${(props) => props.theme.fonts.secondary};
					font-weight: ${(props) => props.theme.fonts.weights.regular};
					font-size: 2.5rem;
				}
			}
		}
	}
`;

export default StyledBuyaHomePage;
